<template>
  <!-- filter area -->
  <div class="p-fluid p-grid p-jc-end print-hidden">
    <!-- right -->
    <div class="p-col-12 p-sm-3" v-if="userLevel == 'AC'">
      <Dropdown
        v-model="areaId"
        :options="areas"
        optionLabel="areaName"
        optionValue="codeId"
        placeholder="กรองข้อมูล พื้นที่"
        :filter="true"
        @change="fetchData"
      />
    </div>
    <div class="p-col-11 p-sm-2">
      <router-link to="/dashboard">
        <Button label="กลับสู่หน้าหลัก" icon="pi pi-arrow-left"
      /></router-link>
    </div>
    <div class="p-col-1">
      <Button icon="pi pi-print" @click="print" />
    </div>
  </div>
  <!-- / filter area -->
  <Fieldset>
    <template #legend>
      <i class="pi pi-check" style="fontsize: 2rem"></i>
      วิเคราะห์ข้อมูลพื้นฐานของผู้สูงอายุ
    </template>
    <div>
      การวิเคราะห์ข้อมูลพื้นฐานของผู้สูงอายุ คือการวิเคราะห์ข้อมูลส่วนตัว
      (ยกเว้นข้อมูลการระบุตัวตนผู้สูงอายุ)
      เพื่ออธิบายสภาวะพื้นฐานของผู้สูงอายุที่อยู่ในพื้นที่
      โดยในการแสดงผลข้อมูลส่วนนี้จะประกอบไปด้วย
    </div>
    <div>1.ข้อมูลจำนวนผู้สูงอายุตามเพศ</div>
    <div>2.ข้อมูลจำนวนผู้สูงอายุตามช่วงอายุ</div>
    <div>3.ข้อมูลแสดงค่า BMI ของผู้สูงอายุในแต่ละช่วงอายุ</div>
    <div>4.ข้อมูลรอบเอวของผู้สูงอายุในแต่ละช่วงอายุ</div>
  </Fieldset>
  <Fieldset legend="กราฟแสดงข้อมูลผู้สูงอายุแบ่งตามเพศ">
    <div ref="chartGender" :style="graphStyle"></div>
    <DividerWord :content="words['ELDER01']" />
  </Fieldset>
  <Fieldset legend="กราฟแสดงจำนวนผู้สูงอายุแบ่งตามช่วงอายุ">
    <div ref="chartAgeRange" :style="graphStyle"></div>
    <DividerWord :content="words['ELDER02']" />
  </Fieldset>
  <Fieldset :legend="prop.text" v-for="(prop, name) in images" :key="name">
    <img :src="plots[name]" :style="graphStyle" />
    <DividerWord :content="words[prop.word]" />
  </Fieldset>
  <MoveToTopPage />
</template>

<script>
import axios from 'axios'
import * as _ from 'lodash'
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import am4themes_animated from '@amcharts/amcharts4/themes/animated'

import { imageEncode } from '../../Utility'
import { mixins } from '../Mixins'

import MoveToTopPage from '../../components/MovetoTop'
import DividerWord from './DividerWord'

// Themes begin
am4core.useTheme(am4themes_animated)
// Themes end

export default {
  mixins: [mixins],
  layout: 'Private',
  data() {
    return {
      areaId: 'ALL',
      series: {
        gender: null,
        age: null
      },
      plots: {
        bmi: null,
        waistline_inch: null
      },
      images: {
        bmi: {
          text: 'กราฟแสดงค่าดัชนีมวลกาย (BMI) ของแต่ละช่วงอายุ',
          word: 'ELDER03'
        },
        waistline_inch: {
          text: 'กราฟแสดงรอบเอวแยกตามช่วงอายุ',
          word: 'ELDER04'
        }
      }
    }
  },
  computed: {
    graphStyle() {
      return {
        height: '500px'
      }
    },
    userLevel() {
      const { codeId, userLevel } = this.$store.getters['oauth/session']
      return userLevel
    },
    userArea() {
      const { codeId, userLevel, area } = this.$store.getters['oauth/session']
      if (userLevel == 'AC') {
        return this.areaId == 'ALL' ? null : this.areaId
      } else {
        return area?.codeId
      }
    },
    areas() {
      return this.$store.getters['area/getAreasHasAllLabel']
    }
  },
  watch: {
    series: {
      handler(newVal, oldVal) {
        console.log('newVal ::==', newVal)
        const { gender, age } = newVal
        this.pieGender(gender)
        this.pieAgeRange(age)
      },
      deep: true
    },
    areaId: function (newVal, oldVal) {
      //
      console.log('newVal ::==' + newVal)
      const bmi = this.$refs['BMI' + newVal]
      console.log('bmi ::==', bmi)
      if (bmi) {
        bmi.style.display = 'block'
      }
    }
  },
  async mounted() {
    this.getGraphBarplot()
  },
  async created() {
    try {
      await this.$store.dispatch('area/fetchAreasOrderNameTh')
      this.fetchData()
    } catch (error) {
      this.$toast.add({
        severity: 'error',
        summary: 'เกิดข้อผิดพลาดจากระบบ',
        detail: 'ระบบไม่สามารถให้บริการได้ ขออภัยในความไม่สะดวก',
        life: 3000
      })
    }
  },
  methods: {
    async fetchData() {
      await this.$store.dispatch('word/fetchGraphWords', {
        areaId: this.userArea
      })
      //**************** get graph api*************** */
      const data = await this.$store.dispatch(
        'pivot/fetchGraphSeriesAnalytise',
        {
          url: 'BASICELDER',
          areaId: this.userArea
        }
      )
      console.log('data ::==', data)
      this.series = data
      //**************** get graph api*************** */
      this.getGraphBarplot()
    },
    getGraphBarplot() {
      const { VUE_APP_ML_ENDPOINT } = process.env
      let delay = 500
      let idx = 0
      Object.keys(this.images).forEach((name) => {
        setTimeout(
          async () => {
            this.plots[name] = 'img/analytics/'+this.areaId+'/'+name+'.png?'+new Date().getTime();
          },
          idx == 0 ? 0 : delay + idx * 500
        )
      })
    },
    pieGender(gender) {
      const chart = am4core.create(this.$refs.chartGender, am4charts.PieChart3D)
      chart.hiddenState.properties.opacity = 0 // this creates initial fade-in

      chart.legend = new am4charts.Legend()
      chart.legend.valueLabels.template.disabled = true

      console.log('gender ::==', gender)
      chart.data = [
        {
          label: 'ชาย',
          value: gender?.male
        },
        {
          label: 'หญิง',
          value: gender?.female
        }
      ]

      const series = chart.series.push(new am4charts.PieSeries3D())
      series.dataFields.value = 'value'
      series.dataFields.category = 'label'
      series.colors.list = [am4core.color('#54ccfc'), am4core.color('#f7a0d5')]
    },
    pieAgeRange(age) {
      const chart = am4core.create(
        this.$refs.chartAgeRange,
        am4charts.PieChart3D
      )
      chart.hiddenState.properties.opacity = 0 // this creates initial fade-in

      chart.legend = new am4charts.Legend()
      chart.legend.valueLabels.template.disabled = true
      console.log('age ::==', age)
      chart.data = [
        {
          label: 'ช่วงอายุ 60 -  65 ปี',
          value: age?.age65
        },
        {
          label: 'ช่วงอายุ 66 - 70 ปี',
          value: age?.age70
        },
        {
          label: 'ช่วงอายุ 70 ปีขึ้นไป',
          value: age?.age71
        }
      ]

      const series = chart.series.push(new am4charts.PieSeries3D())
      series.dataFields.value = 'value'
      series.dataFields.category = 'label'
      series.colors.list = [
        am4core.color('#A685E2'),
        am4core.color('#F38BA0'),
        am4core.color('#A7D0CD')
      ]
    }
  },
  components: {
    MoveToTopPage,
    DividerWord
  }
}
</script>

<style>
</style>